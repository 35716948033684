import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"

import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Header from "../components/header"
import Hero from "../components/blog/hero"
import Categories from "../components/blog/categories"
import PostCard from "../components/blog/postCard";

interface PostType {
  title: string,
  publicationDate: string,
  excerpt: string,
  image: any,
  imageCaption: string,
  slug: string,
  category: any,
  fromOffer: boolean
}

const Blog = () => {
  const query = useStaticQuery(graphql`
      query {
          allStrapiPosts {
              nodes {
                  title
                  publicationDate
                  excerpt
                  image {
                      childImageSharp {
                          fluid(maxWidth: 1920, quality: 100) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
                  imageCaption
                  slug
                  category {
                      slug
                  }
                  fromOffer
              }
          }
      }
  `)


  const posts: Array<PostType> = query.allStrapiPosts.nodes

  posts.sort(function(a: PostType, b: PostType) {
    return new Date(b.publicationDate) - new Date(a.publicationDate)
  })

  const newestPost = posts[0]

  return (
    <Layout>
      <Header />
      <Hero />
      <Categories />
      <Helmet title={"Blog skupiony na Tobie | Centrum Medyczne Dent&Beauty | Świdnica"} />
      <div className="last-post-banner wow fadeInUp" data-wow-duration="1s" style={{visibility: 'hidden'}}>
        <div className="row">
          <div className="small-12 columns">
            <div className="inner">
              <div className="image">
                <img
                  src={newestPost.image.childImageSharp.fluid.src}
                  alt={newestPost.imageCaption}
                />
              </div>
              <div className="content">
                <span>NAJNOWSZE NA BLOGU</span>
                <h2 className="title">
                  <Link to={`/${newestPost.category.slug}/${newestPost.slug}`}>
                    {newestPost.title}
                  </Link>
                </h2>
                <div className="post-date">
                  {newestPost.publicationDate}
                </div>
                <div className="excerpt">
                  {newestPost.excerpt}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-posts-list">
        <div className="container">
          <div className="row">
            {posts.map((post: PostType, index: number) => (
              <PostCard key={index} post={post} index={index} category={post.category}/>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blog
